<template>
  <div class="quiz-step-page desktop-container m-auto bg-center bg-top base__line">
    <div class="desktop-screen quiz-step-container relative m-auto ">
      <div class="bg-mobile pt-4">
        <logo-icon></logo-icon>

        <div class="quiz-header">
          <div class="content m-auto my-8 p-4 pl-6 text-white font-lato">
            <img
              class="title-numerotation absolute"
              :src="getAnswerImage($t(`quiz.${$route.name}.numerotation`))"
              alt
              srcset
            />
            <div class="h-full center-vertically">
              <div class="relative">
                <p class="text-md mb-1">{{$t(`quiz.${$route.name}.instruction`)}}</p>
                <p class="question-text text-lg font-latoBold" v-html="$t(`quiz.${$route.name}.question`)"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="slider-container -mt-8 pb-8">
          <NSlideshow
            :current-slide="slideEnabled ? currentSlide : 2"
            :threshold="0.1"
            :infinite-scroll="false"
            :drag-enabled="slideEnabled"
            :wheel-enabled="slideEnabled"
            class="my-slideshow"
            @change="(slide) => currentSlide = slide"
          >
            <NSlide
              v-for="answer in $t(`quiz.${$route.name}.answers`)"
              :key="answer.value"
            >
              <div
                @click="selectAnswer(answer.value)"
                class="slide-container m-auto my-8 p-4 cursor-pointer" 
                :class="{'slide-selected': answer.value == slideSelected}"
              >
                <img
                  class="answer-numerotation absolute"
                  :src="getAnswerImage(answer.letter_image)"
                  alt
                  srcset
                />
                <div class="content center-vertically">
                  <div class="relative">
                    <img
                      class="answer-icon m-auto"
                      :src="getAnswerImage(answer.image)"
                      alt
                      srcset
                    />
                    <p class="answer text-md font-latoBold text-white text-center mt-4">{{answer.description}}</p>
                  </div>
                </div>
              </div>
            </NSlide>
          </NSlideshow>
          <div class="error-container w-full text-center">
            <span v-if="hasError" class="text-error font-lato">
              {{textError}}
            </span>
          </div>

          <span class="table m-auto mt-5">
            <NCta
              borderRadius="30px"
              backgroundColor="#FFD400"
              textColor="#014457"
              data-cy="submit-register"
              :disabled="isLoading"
              @click.native="submitAnswer()"
              designType="round"
              ref="form_submit_button"
            >
            <span class="text-md font-oswaldBold uppercase">{{$t('quiz.submit')}}</span></NCta>
          </span>
        </div>
      </div>
      <SiteFooter></SiteFooter>
    </div>
    <div class="gaming-bg"></div>
  </div>
</template>

<script>
import { NSlideshow, NSlide } from '@team-uep/n-slideshow'
import { mapActions } from 'vuex'
import LogoIcon from "@/components/icons/LogoIcon";
export default {
  name: 'QuizStepPage',
  components: {
    NSlideshow,
    NSlide,
    LogoIcon
  },
  data () {
    return {
      currentSlide: 1,
      slideSelected: null,
      width: 0,
      isLoading: false,
      textError: "Sélectionnez une réponse avant de valider",
      hasError: false
    }
  },
  computed: {
    // on desktop display, turn OFF the slide and center the slideshow on the second element
    // on mobile display, turn ON the slide
    slideEnabled () {
      return this.width < 720;
    },
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    selectAnswer(value) {
      this.slideSelected = value;
      this.hasError = false;
    },
    submitAnswer() {
      if(this.slideSelected) {
        this.hasError = false;
        this.isLoading = true;
        this.$requestAPI
        .qualifRegister({
          oResponses: {
            [this.$t(`quiz.${this.$route.name}.question_id`)]: this.slideSelected,
          },
        })
        .then((response) => {
          this.isLoading = false;
          // this.slideSelected = null;
          this.$router.push({name: this.$route.meta.nextPage})
        })
        .catch(console.log);
      } else {
        this.hasError = true;
      }
    },
    getAnswerImage(image){
      return require(`../assets/quiz/${image}`)
    },
    handleResize() {
      this.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (this.$route.name == "quiz1") {
      this.updateModalConfig({ isOpen: true, modalComponent: 'QuizRulesModal', modalClass: 'quiz-rules-modal' })
    }
  },
  mounted() {
    this.$mmTro.sendRtg();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>

.quiz-header {
  margin-left: 2rem;
  margin-right: 2rem;
  .content {
    background-color: #0D4355;
    width: fit-content;
    max-width: 580px;
    padding-top: 10px !important;
  }
  .title-numerotation {
    height: 50px;
    width: auto;
    margin-top: -2.5rem;
    margin-left: -3rem;
  }
}
.slider-container {
  .slide-container {
    background-color: #0D4355;
    border: 2px solid white;
    border-radius: 10px;
    width: 205px;
    height: 232px;
    overflow-y: visible;
    .answer-numerotation {
      height: 50px;
      width: auto;
      margin-top: -2rem;
      margin-left: -2rem;
    }
    .answer-icon {
      height: 83px;
      width: auto;
    }
    .content {
      height: 198px;
    }
  }
  .slide-selected {
    border: 6px solid #FFD400;
    width: 220px;
    height: 240px;
  }
}
.n-cta ::v-deep {
  width: 200px;
  padding: 8px 25px !important;
  background-color: #FFD400 !important;
  font-family: "Oswald Bold";
}

.quiz-step-container {
  line-height: 20px;
  .container {
    // min-height: 600px;
  }
}
li {
  margin: 1rem 2rem;
}

.my-slideshow {
  ::v-deep .n-slideshow-container {
    height: 17rem;
    width: 14rem;
    img {
      pointer-events: none;
      width: 90%;
    }
  }
}

.question-text ::v-deep {
  span {
    white-space: nowrap;
  }
}

.center-vertically {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-mobile {
  min-height: 530px;
  @media(max-width: 720px) {
    height: 100vh;
    min-height: 100vh;
  }
}

.error-container {
  height: 15px;
  .text-error {
    color: white;
  }
}
</style>
